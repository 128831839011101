import React, { useEffect } from "react";
import TopNotification from "./components/topNotification";
import Header from "./sections/global/header";
import Footer from "./sections/global/footer";
import Body from "./sections/global/body";
import Maintenance from "./pages/maintenance";
import { useLocation, useNavigate } from "react-router-dom";

function App() {
  const { pathname, location } = useLocation();
  const localStorage = window.localStorage.getItem("is-admin");

  useEffect(() => {
    window.dataLayer.push({
      event: "pageview",
    });
  }, [pathname]);

  return (
    <>
      {/* {localStorage ? (
        <> */}
      <TopNotification />
      <Header />
      <Body />
      <Footer />
      {/* </>
      ) : (
        <Maintenance />
      )} */}
    </>
  );
}

export default App;
