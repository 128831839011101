import { createContext, useState } from "react";

const ModalContext = createContext({});

export const ModalProvider = ({ children }) => {
  const [openLogin, setOpenLogin] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);

  return (
    <ModalContext.Provider
      value={{
        openLogin,
        setOpenLogin,
        addressModal,
        setAddressModal,
        logoutModal,
        setLogoutModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContext;
