import React, { Suspense } from "react";
import routes from "../../routes";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

function Body() {
  const { pathname, location } = useLocation();

  return (
    <main className="main-content">
      <Suspense fallback={<Loading />}>
        <Routes>
          {routes.map((mroute, idx) => {
            return (
              mroute.component && (
                <Route
                  path={mroute.path}
                  key={idx}
                  element={<mroute.component />}
                />
              )
            );
          })}
          <Route
            path="*"
            element={
              <div className="container 2xl:px-[100px] h-[50vh] flex justify-center items-center">
                <h1>Page not found</h1>
              </div>
            }
          />
        </Routes>
      </Suspense>
    </main>
  );
}

const Loading = () => {
  return (
    <div className="loading">
      <h1>Loading...</h1>
    </div>
  );
};

export default Body;
