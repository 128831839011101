import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "../api/axios";
import Modal from "./modal";
import useAuth from "../hooks/useAuth";

function LogoutModal({ isOpen, setOpen }) {
  const navigate = useNavigate();

  const { setAuth } = useAuth();

  const logout = () => {
    axios
      .post("/api/auth/user/logout")
      .then((res) => {
        setOpen(false);
        setAuth({});
        navigate("/");
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <Modal isOpen={isOpen} setOpen={setOpen} heading={"Logout"}>
      <h5 className="text-center mb-8">Are you sure want to logout?</h5>

      <div className="flex justify-between items-center gap-3 md:gap-8">
        <button className="outlined w-full" onClick={() => setOpen(false)}>
          Cancel
        </button>
        <button className="primary w-full" onClick={() => logout()}>
          Logout
        </button>
      </div>
    </Modal>
  );
}

export default LogoutModal;
