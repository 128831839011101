import React, { useEffect } from "react";
import useBodyClass from "../hooks/addBodyClass";
import headerLogo from "../assets/Demo/Header-Logo.svg";

function Maintenance() {
  useBodyClass(["in-maintenance", "fixed-header"]);

  return (
    <div className="container 2xl:px-[100px] h-[100vh] text-center flex justify-center items-center">
      <div>
        <img src={headerLogo} alt="logo" className="w-[50%] m-auto" />
        <br />
        <h1>Currently under maintenance</h1>
      </div>
    </div>
  );
}

export default Maintenance;
