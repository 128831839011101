import React, { useState, useEffect } from "react";
import Close from "../assets/Icons/close_icon.svg";
import Divider from "./divider";

function Drawer(props) {
  const { position, isOpen, setOpen, heading, footer } = props;

  useEffect(() => {
    document
      .getElementsByTagName("html")[0]
      .setAttribute("class", isOpen ? "lock-scroll" : "");
  }, [isOpen]);

  return (
    <>
      <div
        className={`backdrop ${isOpen ? "open" : ""}`}
        onClick={() => setOpen(false)}
      ></div>
      <div className={`drawer ${position} ${isOpen ? "open" : ""}`}>
        <div className="drawer-header">
          <h2>{heading}</h2>
          <div className="cursor-pointer" onClick={() => setOpen(false)}>
            <img className=" w-[16px]" src={Close} />
          </div>
        </div>
        {/* <hr /> */}
        <div className="drawer-body">{props.children}</div>
        {footer && <div className="drawer-footer">{footer}</div>}
      </div>
    </>
  );
}

export default Drawer;
