import React from "react";
import { NavLink } from "react-router-dom";
import Demo8 from "../assets/Demo/Demo-8.png";

const MegaMenu = ({ menus, title }) => {
  return (
    <div
      className={`bg-white-200 absolute z-30 py-[80px] left-0 right-0 top-[100%] mega-menu`}
    >
      <div className="container flex justify-between">
        <div className="">
          <h2 className="text-black-300 pb-4">{title}</h2>
          <div className="flex justify-between pt-12 gap-20">
            {menus.map((data, index) => {
              if (data !== null) {
                return (
                  <ul key={index}>
                    <h4 className="pb-4">{data[0].heading}</h4>
                    {data.map((res, index2) => {
                      return (
                        <li key={index2} className="pb-3">
                          {/* <NavLink to={`${res.url}`}>{res.title}</NavLink> */}
                          <a href={`${res.url}`}>{res.title}</a>
                        </li>
                      );
                    })}
                  </ul>
                );
              }
            })}
          </div>
        </div>
        <div className="justify-end">
          <img src={Demo8} className="w-80" />
        </div>
      </div>
    </div>
  );
};

export default MegaMenu;
